<template>
  <div class="iss-wrapper">
    <a-row :gutter="16">
      <a-col :span="8">
        <div class="main-tree">
          <div class="flex mb-10">
            <a-input-search
              class="mr-8"
              placeholder="请输入名称"
              @change="handleChange"
            />
            <a-space :size="8">
              <a-button type="primary" @click="handleClickAdd">
                <template #icon><plus-outlined /></template>
                新增
              </a-button>
              <a-dropdown>
                <template #overlay>
                  <a-menu>
                    <a-menu-item key="1" @click="handleClickDelete">
                      <delete-outlined style="font-size: 15px" />
                      批量删除
                    </a-menu-item>
                    <a-menu-item key="2" @click="handleClickExport">
                      <export-outlined style="font-size: 15px" />
                      批量导出
                    </a-menu-item>
                  </a-menu>
                </template>
                <a style="white-space: nowrap">
                  更多
                  <DownOutlined />
                </a>
              </a-dropdown>
            </a-space>
          </div>
          <a-spin :spinning="spinning">
            <a-tree
              class="iss-tree"
              checkable
              check-strictly
              block-node
              v-model:expanded-keys="expandedKeys"
              v-model:checked-keys="checkedKeys"
              :selected-keys="selectedKeys"
              :tree-data="treeData"
              :replace-fields="{ key: 'id' }"
              @select="handleSelect"
            >
              <template #title="{ label }">
                <div class="iss-tree-item">
                  <span class="flex-1">{{ label }}</span>
                  <!--              , icon, isEnable    <a-badge :status="isEnable ? 'success' : 'error'" />-->
                  <!--                  <component-->
                  <!--                    :is="icons[icon || 'FileTextOutlined']"-->
                  <!--                    class="fs-16"-->
                  <!--                  />-->
                </div>
              </template>
            </a-tree>
          </a-spin>
        </div>
      </a-col>
      <a-col :span="16">
        <div class="main-content">
          <!--          <div class="fs-20 fw-500 mr-20 ml-20">-->
          <!--            {{ form.id ? '编辑' : '新增' }}-->
          <!--          </div>-->
          <a-form class="iss-form" :wrapper-col="{ span: 18 }">
            <a-form-item label="父级">
              <span>{{ form.parentName }}</span>
            </a-form-item>
            <a-form-item label="名称" v-bind="validateInfos.name">
              <a-input v-model:value="form.name" />
            </a-form-item>
            <a-form-item label="简称" v-bind="validateInfos.abbreviation">
              <a-input v-model:value="form.abbreviation" />
            </a-form-item>
            <a-form-item label="状态">
              <a-switch
                v-model:checked="form.status"
                checked-children="启用"
                un-checked-children="禁用"
              />
            </a-form-item>
            <a-form-item label="排序">
              <a-input-number v-model:value="form.sortValue" :min="0" />
            </a-form-item>
            <a-form-item label="描述">
              <a-textarea v-model:value="form.remark" />
            </a-form-item>
            <a-form-item>
              <a-button class="iss-btn" type="primary" @click="handleSave"
                >保存</a-button
              >
            </a-form-item>
          </a-form>
        </div>
      </a-col>
    </a-row>
  </div>
  <a-modal
    title="新增"
    width="640px"
    @ok="handleOk"
    centered
    @cancel="handleCancel"
    :visible="visibleModal"
  >
    <div>
      <a-form class="iss-form">
        <a-form-item label="父级">
          <span>{{ form.parentName }}</span>
        </a-form-item>
        <a-form-item label="名称" v-bind="validateInfos.name">
          <a-input v-model:value="form.name" />
        </a-form-item>
        <a-form-item label="简称" v-bind="validateInfos.abbreviation">
          <a-input v-model:value="form.abbreviation" />
        </a-form-item>
        <a-form-item label="状态">
          <a-switch
            v-model:checked="form.status"
            checked-children="启用"
            un-checked-children="禁用"
          />
        </a-form-item>
        <a-form-item label="排序">
          <a-input-number v-model:value="form.sortValue" :min="0" />
        </a-form-item>
        <a-form-item label="描述">
          <a-textarea v-model:value="form.remark" />
        </a-form-item>
      </a-form>
    </div>
  </a-modal>
  <icon-modal
    v-model:visible="visible"
    :init-value="form.icon"
    @fnOk="value => (form.icon = value)"
  />
</template>

<script>
import { createVNode, reactive, toRaw, toRefs } from 'vue';
import { useRoute } from 'vue-router';
import {
  Col,
  Dropdown,
  Form,
  InputNumber,
  Menu,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
  Tree,
} from 'ant-design-vue';
import {
  PlusOutlined,
  DeleteOutlined,
  ExportOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
} from '@ant-design/icons-vue';
import { filterTree, getTreeItem } from '@/utils';
import orgApi from '@/api/org.js';

export default {
  components: {
    ARow: Row,
    ACol: Col,
    ASpace: Space,
    ASpin: Spin,
    ATree: Tree,
    AForm: Form,
    AFormItem: Form.Item,
    ASwitch: Switch,
    AInputNumber: InputNumber,
    ADropdown: Dropdown,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    PlusOutlined,
    DeleteOutlined,
    ExportOutlined,
    DownOutlined,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      spinning: false,
      visibleModal: false,
      treeData: [],
      expandedKeys: [],
      selectedKeys: [],
      checkedKeys: { checked: [] },
    });
    const form = reactive({
      id: '',
      parentId: 0,
      parentName: '根结点',
      name: '',
      abbreviation: '',
      status: true,
      sortValue: 0,
      remark: '',
    });
    const required = { required: true, message: '不能为空' };
    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      name: [required],
    });
    let cacheTrees = [];
    const initTreeData = () => {
      state.spinning = true;
      orgApi
        .getList(route.path)
        .then(data => {
          cacheTrees = data.concat();
          state.treeData = data;
          state.expandedKeys = data.map(i => i.id);
        })
        .finally(() => {
          state.spinning = false;
        });
    };
    const handleClickAdd = () => {
      state.selectedKeys = [];
      const checked = state.checkedKeys.checked;
      if (checked.length) {
        if (checked.length == 1) {
          resetFields();
          state.visibleModal = true;
          form.parentId = checked[0];
          form.parentName = getTreeItem(
            cacheTrees,
            item => item.id === checked[0]
          )?.name;
        } else if (checked.length > 1) {
          message.info('只能选中一条数据新增其子节点');
        }
      } else {
        resetFields();
        state.visibleModal = true;
      }
    };
    const handleSave = () => {
      validate().then(() => {
        let type = 'add';
        form.id && (type = 'update');
        orgApi[type](`org:${type}`, toRaw(form)).then(() => {
          message.success('操作成功');
          // handleClickAdd();
          state.visibleModal = false;
          initTreeData();
        });
      });
    };
    initTreeData();
    return {
      ...toRefs(state),
      form,
      validateInfos,
      handleClickAdd,
      handleOk: () => {
        handleSave();
      },
      handleCancel: () => {
        resetFields();
        state.visibleModal = false;
      },
      handleSave,
      handleClickDelete: () => {
        const ids = state.checkedKeys.checked;
        if (ids.length) {
          Modal.confirm({
            title: `确定要删除选中的 ${ids.length} 条数据吗？`,
            icon: createVNode(ExclamationCircleOutlined),
            okType: 'danger',
            onOk: () => {
              orgApi.delete('org.delete', { ids }).then(() => {
                message.success('操作成功');
                state.checkedKeys.checked = [];
                resetFields();
                initTreeData();
              });
            },
          });
        } else {
          message.info('请至少选择一条数据');
        }
      },
      handleClickExport: () => {},
      handleChange: ({ target }) => {
        const { trees, parents } = filterTree(
          cacheTrees,
          item => item.name.indexOf(target.value) > -1
        );
        state.treeData = trees;
        state.expandedKeys = parents.map(i => i.id);
      },
      handleSelect: (keys, { selected, selectedNodes }) => {
        if (selected) {
          state.selectedKeys = keys;
          const node = selectedNodes[0].props.dataRef;
          Object.assign(form, node, {
            parentName:
              node.parentId === '0'
                ? '根结点'
                : getTreeItem(cacheTrees, item => item.id === node.parentId)
                    ?.name,
          });
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-wrapper {
  margin: 16px;
}
.main-tree {
  padding: 20px;
  border-radius: 8px;
  //box-shadow: @box-shadow-base;
  background: #ffffff;
}
.main-content {
  padding: 20px;
  border-radius: 8px;
  background: #ffffff;
  //box-shadow: @box-shadow-base;
  .iss-form {
    height: calc(100vh - 138px);
  }
}
.iss-tree {
  height: calc(100vh - 170px);
  overflow-y: auto;
  .iss-tree-item {
    display: flex;
    align-items: center;
  }
}
.iss-form {
  overflow-y: auto;
  :deep(.ant-form-item-label) {
    width: 52px;
  }
}
.iss-btn {
  margin-left: 52px;
}
.iss-tab {
  :deep(.ant-tabs-small-bar) {
    .ant-tabs-tab {
      padding-top: 0;
    }
  }
  :deep(.ant-tabs-bar) {
    margin: 0px 0px 0px 20px;
  }
  :deep(.ant-tabs-tabpane) {
    padding-bottom: 10px;
  }
}
</style>
